// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$trello-whatsapp-primary: mat-palette($mat-indigo);
$trello-whatsapp-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$trello-whatsapp-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$trello-whatsapp-theme: mat-light-theme(
  (
    color: (
      primary: $trello-whatsapp-primary,
      accent: $trello-whatsapp-accent,
      warn: $trello-whatsapp-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($trello-whatsapp-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
mat-dialog-container {
  padding: 0 !important;
  overflow: visible !important;
}
  .custom-messagebox mat-dialog-container{
    border-radius: 10px !important;
  }
.mat-form-field-infix {
  border-top: 0 solid transparent;
}
.custom-helppopup .mat-dialog-content
{
overflow: hidden !important;
}
.custom-helppopup .mat-dialog-container
{
  overflow: hidden !important;
}
.custom-helppopup #mat-dialog-0
{
  overflow: hidden !important;
}
.ngx-spinner-overlay > div:not(.loading-text) {
  display: flex;
  justify-content: center;
}
mat-label {
  font-size: 0.7rem;
  color: #9eabb3;
}
.custom-dialog-container {
  position: relative;
  background: transparent;
  width: 80%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.custom-dialog-container > .mat-dialog-container {
  background: transparent;
  overflow: visible !important;
}
.custom-dialog-content {
  max-width: 100%;
  max-height: 100%;
}
.blurred {
  filter: blur(2.5px);
  /* Adjust the pixel value based on your preference */
}
#mat-dialog-0 {
  overflow: visible !important;
}
.todo-card.cdk-drag-animating, 
.contact-item.cdk-drag-animating {
  transition: transform 250ms ease;
}
